<template>
    <div class="single-news mb-30">
        <div class="news-img">
            <router-link :to="`/galeria/${galeria.id}/${galeria.slug}`"> <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + galeria.capa" class="img-fluid" :alt="galeria.titulo"></router-link>
                <div class="news-date text-center">
                   
                        <p>{{ galeria.publicacao | moment("D") }}</p>
                        <span>{{ galeria.publicacao | moment("MMM") }}</span>
                </div>
               
                <div class="news-caption">
                    <h2><router-link :to="`/galeria/${galeria.id}/${galeria.slug}`"> {{ galeria.titulo }}</router-link></h2>
                     <p><router-link :to="`/galeria/${galeria.id}/${galeria.slug}`"> {{ galeria.resumo }}</router-link></p>
                    <router-link class="btn btn-custom" style='color:#065199' :to="`/galeria/${galeria.id}/${galeria.slug}`">SAIBA MAIS [+]</router-link>
                 </div>
        </div>
    </div>        
</template>

<script>
import moment from 'moment'
    export default {
        props: ['galeria'],
        
    };
</script>
<style scoped>
.btn-custom {
    color: #065199!important;
}
</style>
